// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medbulletin-js": () => import("./../../../src/pages/medbulletin.js" /* webpackChunkName: "component---src-pages-medbulletin-js" */),
  "component---src-pages-no-login-js": () => import("./../../../src/pages/no-login.js" /* webpackChunkName: "component---src-pages-no-login-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sample-size-guidelines-js": () => import("./../../../src/pages/sample-size-guidelines.js" /* webpackChunkName: "component---src-pages-sample-size-guidelines-js" */),
  "component---src-pages-sample-submission-guidelines-js": () => import("./../../../src/pages/sample-submission-guidelines.js" /* webpackChunkName: "component---src-pages-sample-submission-guidelines-js" */),
  "component---src-pages-services-elemental-impurities-js": () => import("./../../../src/pages/services/elemental-impurities.js" /* webpackChunkName: "component---src-pages-services-elemental-impurities-js" */),
  "component---src-pages-services-microbials-and-contaminants-js": () => import("./../../../src/pages/services/microbials-and-contaminants.js" /* webpackChunkName: "component---src-pages-services-microbials-and-contaminants-js" */),
  "component---src-pages-services-mycotoxin-js": () => import("./../../../src/pages/services/mycotoxin.js" /* webpackChunkName: "component---src-pages-services-mycotoxin-js" */),
  "component---src-pages-services-pesticides-js": () => import("./../../../src/pages/services/pesticides.js" /* webpackChunkName: "component---src-pages-services-pesticides-js" */),
  "component---src-pages-services-potency-js": () => import("./../../../src/pages/services/potency.js" /* webpackChunkName: "component---src-pages-services-potency-js" */),
  "component---src-pages-services-residual-solvents-js": () => import("./../../../src/pages/services/residual-solvents.js" /* webpackChunkName: "component---src-pages-services-residual-solvents-js" */),
  "component---src-pages-services-terpene-testing-js": () => import("./../../../src/pages/services/terpene-testing.js" /* webpackChunkName: "component---src-pages-services-terpene-testing-js" */),
  "component---src-pages-services-water-activity-js": () => import("./../../../src/pages/services/water-activity.js" /* webpackChunkName: "component---src-pages-services-water-activity-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-quote-js": () => import("./../../../src/pages/thank-you-quote.js" /* webpackChunkName: "component---src-pages-thank-you-quote-js" */)
}

